// src/components/Login.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import logo from '../assets/logo.png';
import '../global.css'; // Import global CSS
import './Login.css'; // Import component-specific CSS

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSigningUp, setIsSigningUp] = useState(true); // Toggle between SignUp and SignIn
  const navigate = useNavigate(); // Use React Router's useNavigate hook

  // Email/Password Sign-In
  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      console.log("Attempting email login with:", email, password); // Debug
      await signInWithEmailAndPassword(auth, email, password);
      console.log("User signed in with Email!");
      navigate('/'); // Redirect after successful login
    } catch (error) {
      console.error("Email Sign-In Error:", error.message);
      alert(error.message); // Alert user in case of an error
    }
  };

  // Email/Password Sign-Up
  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      console.log("Attempting sign-up with:", email, password); // Debug
      await createUserWithEmailAndPassword(auth, email, password);
      console.log("User signed up with Email!");
      navigate('/'); // Redirect after successful sign-up
    } catch (error) {
      console.error("Sign-Up Error:", error.message);
      alert(error.message); // Alert user in case of an error
    }
  };

  return (
    <div className="login-container">
      {/* Centered Logo */}
      <img src={logo} alt="Logo" className="logo mb-6" />

      {/* Descriptive Text */}
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-2 text-center text-gray-800 custom-font">
        Streamline Your Job Search with Personalized Cover Letters and Resumes
      </h1>

      {/* Email/Password Login Form */}
      <div className="form-container">
        <form onSubmit={isSigningUp ? handleSignUp : handleEmailLogin} className="w-full flex flex-col items-center">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="input mb-4"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="input mb-4"
          />
          <button type="submit" className="button hp">
            {isSigningUp ? 'Sign Up' : 'Login'}
          </button>
        </form>
      </div>

      <p className="toggle-text">
        {isSigningUp ? 'Already have an account?' : "Don't have an account?"}
        <span onClick={() => setIsSigningUp(!isSigningUp)} className="toggle-link">
          {isSigningUp ? 'Login' : 'Sign Up'}
        </span>
      </p>

      {/* Terms of Service Link */}
      <div className="links">
        <Link to="/terms" className="text-blue-500 underline">Terms of Service</Link>
      </div>
    </div>
  );
};

export default Login;
