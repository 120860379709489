// src/components/JobPostings.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig'; // Firestore
import { collection, query, where, getDocs, addDoc, serverTimestamp, doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth"; // Add this import
import { auth } from '../firebaseConfig'; // Import auth directly
import { signOut } from 'firebase/auth'; // Import signOut from firebase/auth
import { generateCoverLetter, extractJobKeywords, generateNewResume } from '../utils/gpt4'; // GPT-4 functions
import { ref, getDownloadURL } from 'firebase/storage'; // Firebase Storage
import { storage } from '../firebaseConfig'; // Firebase Storage config
import './JobPostings.css'; // Import the new CSS file
import '../global.css'; // Import the global CSS file
import UploadResume from './UploadResume'; // Import the UploadResume component
import ModalComponent from './ModalComponent'; // Assuming you've created this component
import { onSnapshot } from "firebase/firestore"; // Add this import if not already there


// Helper function to sanitize job data
const sanitizeJobData = (job) => {
  // Join array elements into a single string, or fall back to "Not available"
  const qualificationsText = Array.isArray(job.parsedQualifications)
    ? job.parsedQualifications.join(" ") 
    : (job.parsedQualifications || "Not available");

  const responsibilitiesText = Array.isArray(job.parsedResponsibilities)
    ? job.parsedResponsibilities.join(" ") 
    : (job.parsedResponsibilities || "Not available");

  return {
    jobName: job.jobName || "Not available",
    companyName: job.companyName || "Not available",
    qualifications: qualificationsText.substring(0, 1000), // Limit to 1000 characters
    responsibilities: responsibilitiesText.substring(0, 1000) // Limit to 1000 characters
  };
};

// Helper function to sanitize resume data
const sanitizeResumeData = (resume) => {
  return {
    workExperience: (resume.workExperience || "No work experience listed.").substring(0, 1000), // Limit to 1000 characters
    education: (resume.education || "No education listed.").substring(0, 1000), // Limit to 1000 characters
    achievements: (resume.achievements || "No achievements listed.").substring(0, 1000) // Limit to 1000 characters
  };
};

const JobPostings = () => {
  const [jobName, setJobName] = useState("");
  const [companyName, setCompanyName] = useState(""); // New company name state
  const [jobDescription, setJobDescription] = useState("");
  const [jobList, setJobList] = useState([]); // Store existing jobs as an array
  const [parsedResume, setParsedResume] = useState(""); // Store parsed resume data
  const [loading, setLoading] = useState(false);
  const [coverLetter, setCoverLetter] = useState("");
  const [resumeSuggestions, setResumeSuggestions] = useState(""); // Store resume suggestions
  const [responsibilities, setResponsibilities] = useState(""); // Add responsibilities state
  const [qualifications, setQualifications] = useState(""); // Add qualifications state
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [contentReady, setContentReady] = useState(false);

 // Function to fetch user data from Firestore
const fetchUserData = async () => {
  if (auth.currentUser) {
    try {
      const userDocRef = doc(db, "users", auth.currentUser.uid);
      const userSnapshot = await getDoc(userDocRef);
      
      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        console.log("User data fetched successfully:", userData);
        // You can set additional state variables here if needed
      } else {
        console.error("No user document found for the current user.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }
};

  


// Define fetchJobs here
const fetchJobs = async () => {
  if (auth.currentUser) {
    const q = query(collection(db, "jobPostings"), where("userId", "==", auth.currentUser.uid));
    const querySnapshot = await getDocs(q);
    const jobs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setJobList(jobs);
  }
};

// Real-time listener for fetching resume data
const fetchResume = () => {
  if (auth.currentUser) {
    const q = query(collection(db, "resumes"), where("userId", "==", auth.currentUser.uid));
    
    // Set up a real-time listener for resume data
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const resumeData = querySnapshot.docs[0].data();
        console.log("Parsed Resume Data (Real-time): ", resumeData);
        setParsedResume(resumeData); // Set the state with real-time data
      } else {
        console.error('No resume found for this user (Real-time listener).');
        setParsedResume(null); // Ensure state is cleared if no data found
      }
    }, (error) => {
      console.error("Error fetching real-time resume data:", error);
      setParsedResume(null); // Ensure state is cleared on error
    });

    // Clean up listener when component unmounts or user changes
    return unsubscribe;
  }
};


// Only open modal when the content is not null or empty
const handleOpenModal = (content) => {
  if (content) {
    setModalContent(content);
    setContentReady(true); // Indicate that the content is ready
    setModalOpen(true); // Open the modal
  } else {
    console.error("No content to display in the modal");
  }
};



const handleCloseModal = () => {
  setModalOpen(false); // Close the modal
  setModalContent(""); // Clear modal content on close
  setContentReady(false); // Reset the content readiness
};

  
  const handleLogout = () => {
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });
  };


// Single useEffect for auth state observer and data fetching
useEffect(() => {
  // Add an authentication state observer to detect user changes
  const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
    if (user) {
      // If the user is logged in, fetch their data
      fetchUserData(); // Fetch the user's data
      const unsubscribeResume = fetchResume(); // Fetch resume data and get the cleanup function
      fetchJobs(); // Fetch job postings

      // Cleanup function to run when component unmounts or user logs out
      return () => {
        if (unsubscribeResume) unsubscribeResume();
      };
    } else {
      // If no user is logged in, clear the current data
      setParsedResume(null);
      setJobList([]);
    }
  });

  // Cleanup the auth state subscription on component unmount
  return () => unsubscribeAuth();
}, [auth]);


  // Extract the Job Details from the qualifications and responsibilities
  const extractJobDetails = (jobDescription) => {
    // More inclusive regex patterns to capture qualifications and responsibilities sections in detail
    const qualificationsPattern = new RegExp(
      '(qualifications|requirements|what you\'ll bring|skills|abilities|experience)([\\s\\S]*?)(\\n{2,}|what you\'ll do|responsibilities|$)', 
      'i'
    );
  
    const responsibilitiesPattern = new RegExp(
      '(responsibilities|what you\'ll do|your role|your day-to-day|key duties|what you will do)([\\s\\S]*?)(\\n{2,}|experience|qualifications|knowledge, skills, abilities|$)', 
      'i'
    );
  
    // Match sections using the updated regex patterns
    const qualificationsMatch = jobDescription.match(qualificationsPattern);
    const responsibilitiesMatch = jobDescription.match(responsibilitiesPattern);
  
    // Extract the matched text or fallback to 'Not available' if no match is found
    let qualificationsText = qualificationsMatch ? qualificationsMatch[2]?.trim() : 'Not available';
    let responsibilitiesText = responsibilitiesMatch ? responsibilitiesMatch[2]?.trim() : 'Not available';
  
    // Split the text into an array by looking for common separators (e.g., newline, bullets, dashes)
    const splitTextIntoArray = (text) => {
      return text
        .split(/\n|•|-/) // Split by newline, bullet markers, or dashes
        .map(line => line.trim()) // Trim whitespace
        .filter(line => line.length > 0); // Remove empty lines
    };
  
    // Create an array of qualifications and responsibilities
    const qualifications = splitTextIntoArray(qualificationsText);
    const responsibilities = splitTextIntoArray(responsibilitiesText);
  
    // Log the parsed sections for debugging
    console.log("Extracted Qualifications (Array):", qualifications);
    console.log("Extracted Responsibilities (Array):", responsibilities);
  
    return { qualifications, responsibilities };
  };
  
  
  // Add a new job posting to Firestore
  const handleAddJob = async () => {
    try {
      // Ensure that qualifications and responsibilities are captured directly
      const parsedQualifications = qualifications.trim() 
        ? qualifications.split("\n").map((item) => item.trim()).filter((item) => item.length > 0)
        : ["Not available"];
  
      const parsedResponsibilities = responsibilities.trim() 
        ? responsibilities.split("\n").map((item) => item.trim()).filter((item) => item.length > 0)
        : ["Not available"];
  
      await addDoc(collection(db, "jobPostings"), {
        jobName,
        companyName,
        parsedQualifications, // Store parsed qualifications as an array
        parsedResponsibilities, // Store parsed responsibilities as an array
        createdAt: serverTimestamp(),
        userId: auth.currentUser.uid, // Store the current user's ID
      });
  
      // Update job list locally
      const updatedJobs = [...jobList, { jobName, companyName, parsedQualifications, parsedResponsibilities }];
      setJobList(updatedJobs);
      setJobName("");
      setCompanyName(""); // Clear company name input
      setQualifications(""); // Clear qualifications field
      setResponsibilities(""); // Clear responsibilities field
    } catch (error) {
      console.error("Error adding job:", error);
    }
  };
  

  // Generate cover letter using the stored parsed job data
  const handleGenerateCoverLetter = async (type, jobId) => {
    const job = jobList.find(job => job.id === jobId);
  
    // Return if job doesn't exist
    if (!job) return;
  
    setLoading(true); // Show loading state
  
    try {
      // Combine job responsibilities and qualifications for keyword extraction
      const combinedJobDetails = `${job.parsedResponsibilities || ''} ${job.parsedQualifications || ''}`;
      const extractedKeywords = await extractJobKeywords(combinedJobDetails);
  
      const cleanedKeywords = extractedKeywords.flatMap(keyword =>
        keyword.split(/[.,\n]/)
          .map(part => part.trim().toLowerCase())
          .filter(part => part && isNaN(part))
          .filter(part => !["key skills:", "qualifications:", "tools:"].includes(part))
      );
  
      if (!parsedResume) {
        console.error('No parsed resume data available.');
        setLoading(false);
        return;
      }
  
      // System message to instruct GPT model
      const systemMessage = {
        role: 'system',
        content: `You are an expert in writing personalized cover letters for job applications, focusing on key skills relevant to the job description and resume. Use the information provided in the resume to build a convincing case for the applicant.`
      };
  
      // User message containing the job information and resume details
      const userMessage = {
        role: 'user',
        content: `
          Write a ${type} cover letter for the following job posting:
          Job Title: ${job.jobName}
          Company: ${job.companyName}
          Responsibilities: ${job.parsedResponsibilities || "Not available"}
          Qualifications: ${job.parsedQualifications || "Not available"}
          The applicant's relevant work experience, education, and skills include:
          Work Experience: ${parsedResume.workExperience || "Not available"}
          Education: ${parsedResume.education || "Not available"}
          Skills: ${cleanedKeywords.join(', ')}
          Make sure to match the tone: ${type}.
        `
      };
  
      // Generate the cover letter using the GPT model
      const generatedCoverLetter = await generateCoverLetter([systemMessage, userMessage]);
  
      if (generatedCoverLetter) {
        setCoverLetter(generatedCoverLetter); // Store the generated cover letter
        setModalContent(generatedCoverLetter); // Set the modal content to the cover letter
        setModalOpen(true); // Open the modal last to ensure content is ready
      } else {
        console.error('No cover letter returned from GPT-4 API');
      }
    } catch (error) {
      console.error("Error generating cover letter:", error);
    } finally {
      setLoading(false); // Hide loading state
    }
  };
  

  

  // Step 1: Refine Extraction of Work Experience
  const extractSections = (resumeText) => {
    // Extract full work experience section
    const workExperienceMatches = resumeText.match(/professional\s*experience\s*([\s\S]*?)(education|skills|$)/i);
    const workExperience = workExperienceMatches ? workExperienceMatches[1].trim() : "No work experience listed.";
  
    // Extract full education section
    const educationMatches = resumeText.match(/education\s*([\s\S]*?)(work|experience|skills|$)/i);
    const education = educationMatches ? educationMatches[1].trim() : "No education listed.";
  
    // Extract full skills section
    const skillsMatches = resumeText.match(/skills\s*([\s\S]*?)(education|work|experience|$)/i);
    const skills = skillsMatches ? skillsMatches[1].trim() : "No skills listed.";
  
    // Optionally, you can use more refined regex patterns or logic to capture roles within work experience separately
    return { workExperience, education, skills };
  };

// Step 2: Update Resume Generation to Loop through All Roles
const handleGenerateNewResume = async (jobId) => {
  const job = jobList.find(job => job.id === jobId);

  if (!job) {
    console.error('Job not found');
    return;
  }

  if (!parsedResume) {
    console.error('No resume data available.');
    return;
  }

  setLoading(true);

  try {
    const sanitizedJob = sanitizeJobData(job);
    const sanitizedResume = sanitizeResumeData(parsedResume);

    // Extract work experience, education, and skills from the parsed resume text
    const { workExperience, education, skills } = extractSections(parsedResume?.parsedText || "");

    // Create a detailed summary of achievements from parsedResume
    const detailedAchievements = sanitizedResume.achievements
      ? sanitizedResume.achievements.split('\n').map((ach, index) => `Achievement ${index + 1}: ${ach}`).join("\n")
      : "No achievements listed.";

    const systemMessage = {
      role: 'system',
      content: `You are an expert in reviewing and creating professional resumes tailored for specific job applications. Craft resumes that highlight relevant experience, skills, and achievements in a structured manner.`
    };

    const userMessage = {
      role: 'user',
      content: `
        Please generate a new resume for the applicant based on the following job description and their previous resume details.

        **Job Title**: ${sanitizedJob.jobName}
        **Company**: ${sanitizedJob.companyName}
        **Qualifications**: ${sanitizedJob.qualifications}
        **Responsibilities**: ${sanitizedJob.responsibilities}

        **Resume Details**:
        - **Professional Summary**: Summarize the candidate's qualifications with a focus on relevant skills and achievements.
        - **Work Experience**:
          ${workExperience}
        - **Education**:
          ${education}
        - **Key Achievements**:
          ${detailedAchievements}
        - **Skills**: Focus on skills relevant to the job.

        Please structure the resume with clear section headings and use bullet points to highlight key information. Quantify achievements wherever possible.
      `
    };

    const generatedNewResume = await generateNewResume([systemMessage, userMessage]);

    if (generatedNewResume) {
      setResumeSuggestions(generatedNewResume);
      setModalContent(generatedNewResume);
      setModalOpen(true);
    } else {
      console.error('No resume generated from GPT-4 API');
    }

  } catch (error) {
    console.error("Error generating new resume:", error);
  } finally {
    setLoading(false);
  }
};




   // Add the helper function here before the return statement
   const renderList = (dataArray) => {
    return (
      <ul className="list-disc ml-6">
        {dataArray.map((item, index) => (
          <li key={index} className="mb-2">{item}</li>
        ))}
      </ul>
    );
  };

  // UI for adding a job
  return (
    <div id="job-postings" className="p-4 flex-container">
      {loading && (
  <div className="fullscreen-modal">
    <h2>Generating your document...</h2>
    <p>This may take up to a minute. Thank you for your patience!</p>
    <div className="spinner-logo"></div> {/* This will be the animated logo */}
  </div>
)}

      {/* Flex container for UploadResume and JobPostings sections */}
        {/* Upload Resume Section */}
        <div className="upload-resume-section">
          <UploadResume />
        </div>
  
        {/* Add Job Posting Section */}
        <div className="add-job-section">
        <h2 className="custom-heading">Add a Job Posting</h2>
  <input
    type="text"
    value={jobName}
    onChange={(e) => setJobName(e.target.value)}
    placeholder="Job Title (e.g., AI Specialist)"
    className="p-2 border-purple border rounded mb-2 w-full"
  />
  <input
    type="text"
    value={companyName}
    onChange={(e) => setCompanyName(e.target.value)}
    placeholder="Company Name (e.g., Google)"
    className="p-2 border-purple border rounded mb-2 w-full"
  />
  <textarea
    value={responsibilities}
    onChange={(e) => setResponsibilities(e.target.value)}
    placeholder="Enter responsibilities here"
    className="p-2 border-purple border rounded mb-2 w-full"
    rows="2"
  ></textarea>
  <textarea
    value={qualifications}
    onChange={(e) => setQualifications(e.target.value)}
    placeholder="Enter qualifications here"
    className="p-2 border-purple border rounded mb-2 w-full"
    rows="2"
  ></textarea>
  <button
    onClick={handleAddJob}
    className="p-2 bg-purple text-white rounded w-auto"
  >
    Add Job
  </button>
      </div>
  
      {/* Display Job Table */}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Job Title</th>
              <th>Company Name</th>
              <th>Qualifications</th>
              <th>Responsibilities</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobList.map((job) => (
              <tr key={job.id}>
                <td>{job.jobName}</td>
                <td>{job.companyName}</td>
                <td>
                  {job.parsedQualifications && job.parsedQualifications.length > 0
                    ? renderList(job.parsedQualifications)
                    : "No qualifications available"}
                </td>
                <td>
                  {job.parsedResponsibilities && job.parsedResponsibilities.length > 0
                    ? renderList(job.parsedResponsibilities)
                    : "No responsibilities available"}
                </td>
                <td className="actions-container">
  <div className="tooltip-container">
    <button 
      onClick={async () => {
        await handleGenerateCoverLetter('Casual', job.id);
        handleOpenModal(coverLetter);
      }} 
      className="button"
    >
      Coverator
    </button>
    <span className="tooltip-text">
      Generate a casual cover letter based on the job and your resume.
    </span>
  </div>

  <div className="tooltip-container">
    <button 
      onClick={async () => {
        await handleGenerateNewResume(job.id);
        handleOpenModal(resumeSuggestions);
      }} 
      className="button"
    >
      Resumator
    </button>
    <span className="tooltip-text">
      Create a new resume tailored to the job description you uploaded.
    </span>
  </div>
</td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
{/* Conditional rendering for the ModalComponent */}
{modalOpen && (
  <ModalComponent 
    isOpen={modalOpen}
    onClose={handleCloseModal}
    content={modalContent}
  />
)}



    </div>
  );
};

export default JobPostings;