// src/components/UploadResume.js
import React, { useState, useEffect } from 'react';
import { storage, auth, db } from '../firebaseConfig';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, query, where, getDocs, deleteDoc, setDoc, doc, serverTimestamp } from 'firebase/firestore';
import '../global.css';
import './UploadResume.css'; // Create and include a new CSS file for upload-specific styles

const UploadResume = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [resumeURL, setResumeURL] = useState(null);

  const user = auth.currentUser;

  // Fetch existing resume if any
  const fetchResume = async () => {
    if (user) {
      const q = query(collection(db, 'resumes'), where('userId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const resumeData = querySnapshot.docs[0].data();
        setResumeURL(resumeData.downloadURL);
      }
    }
  };

  useEffect(() => {
    fetchResume();
  }, [user]);

  // Handle file drop
  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      setError('');
      console.log('Dropped file:', droppedFile.name);
    } else {
      setError('No file selected');
    }
  };

  // Handle file upload
  // Handle file upload
const handleUpload = async (event) => {
  event.preventDefault();
  if (!file) {
    setError('Please select a file to upload.');
    return;
  }

  setUploading(true);
  try {
    const storageRef = ref(storage, `resumes/${user.uid}/${file.name}`);
    const docRef = doc(collection(db, 'resumes')); // Generate a new unique document ID
    const metadata = {
      customMetadata: {
        docId: docRef.id, // Use the generated document ID in metadata
      },
    };

    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Optional: track progress
      },
      (error) => {
        setError('Error during upload. Please try again.');
        setUploading(false);
        console.error('Upload error:', error);
      },
      async () => {
        try {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('Resume uploaded to:', downloadURL);

          await setDoc(docRef, {
            downloadURL,
            createdAt: serverTimestamp(),
            userId: user.uid,
            userEmail: user.email,
            updatedAt: serverTimestamp(),
          });

          setSuccessMessage('Resume uploaded successfully! Parsing will happen in the background.');
          setResumeURL(downloadURL);
          setFile(null);

          // Trigger fetchResume to update parsedResume state with latest data
          await fetchResume();
        } catch (error) {
          setError('Error saving resume information. Please try again.');
          console.error('Error saving resume info:', error);
        } finally {
          setUploading(false);
        }
      }
    );
  } catch (error) {
    console.error('Upload failed:', error);
    setUploading(false);
  }
};


  // Handle delete resume
  const handleDelete = async () => {
    if (resumeURL) {
      const resumeRef = ref(storage, resumeURL);
      try {
        await deleteObject(resumeRef);
        console.log('Resume deleted from Storage.');

        const q = query(collection(db, 'resumes'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const resumeDocId = querySnapshot.docs[0].id;
          await deleteDoc(doc(db, 'resumes', resumeDocId));
          console.log('Resume metadata deleted from Firestore.');
        }

        setSuccessMessage('Resume deleted successfully.');
        setResumeURL(null);
      } catch (error) {
        setError('Error deleting resume. Please try again.');
        console.error('Delete error:', error);
      }
    } else {
      setError('No resume to delete.');
    }
  };

  return (
    <div className="upload-container">
      <h2 className="upload-title">Upload Your Resume</h2>

      <div
        className="upload-dropzone"
        onDrop={handleFileDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        {file ? (
          <p className="file-info">{file.name}</p>
        ) : (
          <div className="upload-info">
            <img src={require('../assets/logo2.png')} alt="Logo" className="upload-logo" />
            <p className="file-info">Drag & Drop Your PDF Resume Here</p>
          </div>
        )}
      </div>

      {resumeURL && (
        <div className="resume-info">
          <p>
            <a href={resumeURL} target="_blank" rel="noopener noreferrer" className="resume-link">View Current Resume</a>
          </p>
        </div>
      )}

      <div className="action-buttons">
        {resumeURL ? (
          <button onClick={handleDelete} className="button delete-button">
            {uploading ? 'Deleting...' : 'Delete Resume'}
          </button>
        ) : (
          <button onClick={handleUpload} className="button upload-button" disabled={uploading}>
            {uploading ? 'Uploading...' : 'Upload Resume'}
          </button>
        )}
      </div>

      {error && <p className="error-message">{error}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
    </div>
  );
};

export default UploadResume;
