// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore"; // Add Firestore import

// Replace these values with your actual Firebase config
const firebaseConfig = {
    apiKey: "AIzaSyA9cJB67PKGN13mPg9CDJvZ0qAcgbHIoyk",
  authDomain: "res3000.com",
  projectId: "res3000-86609",
  storageBucket: "res3000-86609.appspot.com",
  messagingSenderId: "19343989047",
  appId: "1:19343989047:web:a2c54703877cbcd06022b2",
  measurementId: "G-CLP1MV74C2"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const storage = getStorage(app);
const db = getFirestore(app); // Initialize Firestore

export { auth, provider, storage, db };