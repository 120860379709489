import React, { useEffect } from 'react';
import './ModalComponent.css'; // Ensure this CSS file is created for styling

function ModalComponent({ isOpen, onClose, content }) {
  // Function to copy content to clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(content).then(() => {
      alert('Content copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  // Log the content whenever it's received to ensure it's coming through
  useEffect(() => {
    console.log("Modal content updated:", content);
  }, [content]);

  // If the modal is not open or content is empty, don't render anything
  if (!isOpen || !content) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <button className="close-button" onClick={onClose}>Close</button>
          <button className="copy-button" onClick={handleCopy}>Copy</button> {/* Add copy button here */}
        </div>
        <div className="modal-body">
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
    </div>
  );
}

export default ModalComponent;
