import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from '../assets/logo.png'; // Assuming your logo is located in this path
import '../global.css'; // Importing the global CSS for consistent styling

const TermsOfService = () => {
  return (
    <div className="tos-container">
      <div className="logo-wrapper">
      <Link to="/"> {/* Wrap the logo with Link to navigate to home page */}
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <h1 className="tos-title">
        <span>Resumator 3000 Terms of Service</span> <br />
        <span className="tos-title-small">(a.k.a. “Don't Blame Me for Your Life Choices”)</span>
      </h1>
      <p className="tos-content">
        <strong>Welcome, Brave Soul!</strong><br />
        By using this app, you're acknowledging a few cold, hard truths about life, but hey, we are all adults and I assume that you're over 18 if you're using this. Ready? Here goes.
      </p>
      <ol className="tos-list">
        <li>
          <h2>No Magic Wands Here</h2>
          <p>Think of this app like your digital buddy that takes a resume, some info about a job description, and mashes 'em together to give you a cover letter and resume with a little extra oomph. Do you magically become the CEO of a Fortune 500 company because of it? Nope. And if you expected that… Well, I have a bridge to sell you.</p>
        </li>
        <li>
          <h2>Don't Come Crying to Me</h2>
          <p>If you don’t land that “dream job” you thought you were destined for, it’s not my fault. I didn’t invent reality, I just made an app. Blaming me for not getting hired is like blaming your umbrella for the rain. Sorry, it doesn’t work that way.</p>
        </li>
        <li>
          <h2>Not Your Life Coach</h2>
          <p>I’m not here to hold your hand, boost your self-esteem, or whisper sweet nothings in your ear about your qualifications. If your resume reads like a grocery list and your job history looks like a game of hopscotch, that's not on me. I just polish up what you give me—I'm not pulling miracles out of thin air.  No app is going to make chicken salad from chicken shit as my uncle Rocky so lovingly puts it.</p>
        </li>
        <li>
          <h2>Zero Liability, Zilch, Nada</h2>
          <p>You use this app at your own risk. Your results may vary based on everything from the phase of the moon to how much coffee you’ve had today. If your cover letter turns out to be the linguistic equivalent of a “YOLO” tattoo, well, I can't help you with that. So yeah, you can’t sue me, my cat, or my future kids if things go sideways. We live in a magical future where we tell a machine to do something and it happens, but you have to take responsibility at some point.</p>
        </li>
        <li>
          <h2>Don't Get Weird About It</h2>
          <p>This app is for your own personal use. Don’t be that guy selling the cover letters on a street corner like they're black market concert tickets (psst, buddy, wanna buy a cover letter?). And if you’re thinking of using my app to apply for a job you’re not qualified for, well, all I can say is… good luck, you bold, delusional soul.</p>
        </li>
        <li>
          <h2>Your Data, Not Your Diary</h2>
          <p>Yes, this app needs your resume and a job description to work. But I'm not interested in your ex-girlfriend's number or your collection of cat memes. Keep it relevant. I’m not here to spill your secrets or monetize your life story. Also, don’t come crying to me if you’re dumb enough to upload your Social Security number or the name of your first pet.</p>
        </li>
      </ol>
      <div className="final-section">
        <h2>Happy Job Hunting...or whatevs 😎</h2>
        <p>Peace & Love ✌️❤️</p>
        <p>Schnaars (aka Rhymes with Cars, LLC) 🚗</p>
      </div>
    </div>
  );
};

export default TermsOfService;