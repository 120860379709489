// src/utils/gpt4.js
import axios from 'axios';

const OPENAI_API_KEY = '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'; // Replace with your actual OpenAI API key


// Function to generate a cover letter using GPT-4
export const generateCoverLetter = async (messages) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4',
        messages: messages,
        max_tokens: 2000,
        temperature: 0.7,
      },
      {
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data.choices[0].message.content; 
  } catch (error) {
    console.error('Error generating cover letter:', error.response ? error.response.data : error.message);
    return null;
  }
};

// Function to generate a completely new resume using GPT-4
export const generateNewResume = async (messages) => {
  try {
    const response = await axios.post(
      'https://api.openai.com/v1/chat/completions',
      {
        model: 'gpt-4',
        messages: messages,
        max_tokens: 5000, // Adjust max tokens based on the resume length you want
        temperature: 0.7,
      },
      {
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating new resume:', error.response ? error.response.data : error.message);
    return null;
  }
};

// Example function to extract keywords from the job description
// src/utils/gpt4.js

export const extractJobKeywords = (jobDescription) => {
  // Check if jobDescription is a valid string
  if (!jobDescription || typeof jobDescription !== "string") {
    console.error("Invalid job description provided for keyword extraction.");
    return []; // Return an empty array if the description is not valid
  }

  // Split job description by common delimiters and clean up
  const keywords = jobDescription
    .split(/[.,\n]/) // Split by common delimiters like period, comma, and newline
    .map(keyword => keyword.trim().toLowerCase()) // Trim and convert to lowercase
    .filter(keyword => keyword && isNaN(keyword)); // Remove empty strings and numbers

  return keywords;
};
