// src/components/Header.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'; // Path to your logo

const Header = ({ handleLogout }) => {
  const navigate = useNavigate();

  return (
    <header className="header-container">
    <img src={logo} alt="Logo" className="header-logo" />
    <div className="header-buttons">
      <div className="tooltip-container">
      
        <button
          className="donate-button"
          onClick={() => window.open('https://www.paypal.com/donate/?hosted_button_id=MGAT86326GFQ2', '_blank')}
        >
          Donate $5
        </button>
        <span className="tooltip-text">
          OpenAI doesn't give away their API and running this is expensive. If you got value out of this, maybe consider donating $5 to keep this running.
        </span>
      </div>
      <button
          className="donate-button"
          onClick={() => window.open('https://heavy-pyrite-d7e.notion.site/12e6756306cf80158b60d7265ee441b7', '_blank')}
        >
          Feedback
        </button>
      <button onClick={handleLogout} className="logout-button">
        Logout
      </button>
    </div>
  </header>
  );
};

export default Header;
