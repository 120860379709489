import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { auth } from './firebaseConfig'; // Import auth for checking login status
import { signOut, onAuthStateChanged } from 'firebase/auth'; // Track user state
import Login from './components/Login'; // Import Login component
import JobPostings from './components/JobPostings';
import TermsOfService from './components/TermsOfService'; // Correct import for the TOS component
import Header from './components/Header'; // Import the Header component
import { collection, doc, setDoc } from "firebase/firestore"; // Import Firestore functions
import { db } from './firebaseConfig'; // Firestore instance


const App = () => {
  const [user, setUser] = useState(null);

  // Check if the user is logged in when the app loads
  // Check if the user is logged in when the app loads
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        // Create or update the user document in Firestore
        const userDocRef = doc(collection(db, "users"), currentUser.uid);
        try {
          await setDoc(userDocRef, {
            email: currentUser.email,
            createdAt: new Date().toISOString()
          }, { merge: true }); // Merge to avoid overwriting any existing data
        } catch (error) {
          console.error("Error creating/updating user document:", error);
        }
      }
    });
    return () => unsubscribe(); // Cleanup the listener
  }, []);


  const handleLogout = () => {
    signOut(auth).then(() => {
      setUser(null);
    }).catch((error) => {
      console.error("Logout error:", error);
    });
  };

  return (
    <div>
      {/* Add Header component at the top */}
      {user && <Header handleLogout={handleLogout} />}
    <Routes>
      {/* Public Route for Terms of Service */}
      <Route path="/terms" element={<TermsOfService />} />

      {/* Public Route for Login */}
      <Route path="/login" element={<Login />} />

{/* Protected Route for main content */}
<Route 
  path="/" 
  element={
    user ? (
      <div className="flex">
        {/* Main content */}
        <div className="main-content w-full"> {/* Changed w-4/5 to w-full */}
          
          <JobPostings />
        </div>
      </div>
    ) : (
      <Navigate to="/login" /> // Redirect to login if not authenticated
    )
  } 
/>
</Routes>
</div>
);
};

export default App;
